<template>
    <div class="plans">
        <div class="block"></div>

        <div class="container">
            <div class="grid plans-layout">
                <aside class="grid-item plans-sidebar">
                    <h1 class="page-title">{{ $t("title.reviews") }}</h1>
                    <div class="block-lg">
                        <template v-if="!loading">
                            <BaseMenu v-model="menu" class="reviews-mobile-filter">
                                <h3 slot="activator">
                                    {{ $t("label.filter_review_by_country") }}
                                    <img
                                        :class="['caret', { up: menu }]"
                                        src="@/assets/images/caret-down.svg"
                                    />
                                </h3>

                                <ul class="reviews-filters ">
                                    <li v-for="c in countries" :key="c.code">
                                        <a
                                            href="#"
                                            @click="
                                                country = c.code
                                                menu = false
                                            "
                                            :class="{ active: country == c.code }"
                                        >
                                            <span class="flag"> {{ c.flag }} </span>
                                            <span class="country">
                                                {{ c.name }}
                                            </span>
                                            <span class="ts-quiet number">
                                                {{ c.total }}
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </BaseMenu>
                            <div class="reviews-desktop-filter">
                                <h3>{{ $t("label.filter_review_by_country") }}</h3>
                                <ul class="reviews-filters">
                                    <li v-for="c in countries" :key="c.code">
                                        <a
                                            href="#"
                                            @click="country = c.code"
                                            :class="{ active: country == c.code }"
                                        >
                                            <span class="flag"> {{ c.flag }} </span>
                                            <span class="country">
                                                {{ c.name }}
                                            </span>
                                            <span class="ts-quiet number">
                                                {{ c.total }}
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <div class="block-lg ts-align-center" v-else>
                            <BaseLoader radius="35px" />
                        </div>
                    </div>
                </aside>
                <div class="grid-item plans-body" style="padding-bottom: 80px">
                    <Reviews :limit="10" :country="country" showMore />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Reviews from "@/components/Reviews.vue"
export default {
    components: { Reviews },
    data() {
        return {
            country: null,
            countries: [],
            loading: false,
            menu: false,
        }
    },
    mounted() {
        this.fetchCountries()
    },
    watch: {
        "$i18n.locale": {
            handler() {
                this.fetchCountries()
            },
        },
    },
    methods: {
        async fetchCountries() {
            this.loading = true
            const response = await fetch(
                `${process.env.VUE_APP_API_BASE_URL}/public/reviews-countries?locale=${
                    this.$i18n.locale
                }`,
                {
                    method: "GET",
                }
            )
            this.countries = await response.json()
            this.loading = false
        },
    },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.reviews.title"),
            description: this.$i18n.t("meta.reviews.description"),
        })
    },
}
</script>
